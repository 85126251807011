import { createRouter, createWebHistory } from "vue-router";
import { isAuthenticated } from "@/utils/auth";
// import store from "@/store"; // Import the store to access the user's state

const routes = [
  {
    path: "/",
    name: "login",
    component: () => import("../views/LoginView.vue"), // Lazy loaded
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next("/"); // Redirect to homepage or a dashboard if authenticated
      } else {
        next(); // Proceed to the login page if not authenticated
      }
    },
  },
  {
    path: "/signup",
    name: "signup",
    component: () => import("../views/SignupView.vue"), // Lazy loaded
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next("/"); // Redirect to homepage or a dashboard if authenticated
      } else {
        next(); // Proceed to the login page if not authenticated
      }
    },
  },
  {
    path: "/ourteachers",
    name: "ourteachers",
    component: () => import("@/views/OurTeachersView.vue"), // Lazy loaded
    meta: { requiresAuth: true }, // Protect this route
  },
  {
    path: "/teacherwall/:id",
    name: "teacherwall",
    component: () => import("@/views/TeacherWallView.vue"), // Lazy loaded
    meta: { requiresAuth: true }, // Protect this route
  },
  {
    path: "/singlecourse/:id",
    name: "singlecourse",
    component: () => import("@/views/SingleCourseView.vue"), // Lazy loaded
    meta: { requiresAuth: true }, // Protect this route
  },
  {
    path: "/teacherprofile/:id",
    name: "teacherprofile",
    component: () => import("@/views/ProfileTeacherWallView.vue"), // Lazy loaded
    meta: { requiresAuth: true }, // Protect this route
  },
  {
    path: "/editsinglecourse/:id",
    name: "editsinglecourse",
    component: () => import("@/views/EditSingleCourseView.vue"), // Lazy loaded
    meta: { requiresAuth: true }, // Protect this route
  },
  {
    path: "/admin",
    name: "admin",
    component: () => import("@/views/Admin.vue"), // Lazy loaded
    meta: { requiresAuth: true, role: "Admin" }, // Protect this route for Admin only
  },
  {
    path: "/forgetpassword",
    name: "forgetpassword",
    component: () => import("@/views/ForgetPasswordView.vue"), // Lazy loaded
    // meta: { requiresAuth: true }, // Protect this route
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isAuthenticated()) {
      next({ name: "login" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
