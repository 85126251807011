// store/modules/teacherProfile.js
const state = {
  profile: {},
  user_name: String,
  bio: String,
  description: String,
  image: String,
  social_media: [],
};

const mutations = {
  SET_PROFILE(state, profile) {
    state.profile = profile;
    state.user_name = profile.user_name;
    state.bio = profile.bio;
    state.description = profile.description;
    state.image = profile.image;
    state.social_media = profile.social_media;
  },
  SET_USER_NAME(state, user_name) {
    state.user_name = user_name;
  },
  SET_BIO(state, bio) {
    state.bio = bio;
  },
  SET_DESCRIPTION(state, description) {
    state.description = description;
  },
  SET_IMAGE(state, image) {
    state.image = image;
  },
  SET_SOCIAL_MEDIA(state, social_media) {
    state.social_media = social_media;
  },
};

const actions = {
  setUserName({ commit }, user_name) {
    commit("SET_USER_name", user_name);
  },
};

const getters = {
  profile: (state) => state.profile,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
