import axios from "axios";
import serverURL from "@/config";

const state = {
  profileName: String,
  email: String,
  profileImage: { type: String, default: "/assets/images/default.jpg" },
  password: String,
};

const mutations = {
  SET_PROFILE_NAME(state, name) {
    state.profileName = name;
  },
  SET_PROFILE_EMAIL(state, email) {
    state.email = email;
  },
  SET_PROFILE_PASSWORD(state, password) {
    state.password = password;
  },
  SET_PROFILE_IMAGE(state, imageUrl) {
    state.profileImage = imageUrl;
  },
};

const actions = {
  async fetchAdminInfo({ commit }) {
    console.log(state.userRole);
    try {
      const response = await axios.get(`${serverURL}api/adminuser`, {
        withCredentials: true, // Include cookies in the request
      });
      const data = response.data.value;
      // console.log("Admin data:", response.data);

      // Commit the fetched data to the store
      if (data.name) {
        commit("SET_PROFILE_NAME", data.name);
      }
      if (data.email) {
        commit("SET_PROFILE_EMAIL", data.email);
      }
      if (data.profileImage) {
        commit("SET_PROFILE_IMAGE", data.profileImage);
      } else {
        commit("SET_PROFILE_IMAGE", "/assets/images/default.jpg"); // Ensure default image is set
      }
    } catch (error) {
      console.error("Error fetching admin info:", error);
    }
  },
};

const getters = {
  profileName: (state) => state.profileName,
  email: (state) => state.email,
  profileImage: (state) => state.profileImage,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
