const state = {
  id: "",
  firstName: "",
  secondName: "",
  thirdName: "",
  grade: "",
  userRole: "",
  profileImage: { type: String, default: "/assets/images/default.jpg" },
};

const mutations = {
  SET_USER(state, user) {
    state.id = user.id;
    state.firstName = user.firstName;
    state.secondName = user.secondName;
    state.thirdName = user.thirdName;
    state.grade = user.grade;
    state.userRole = user.userRole;
    state.profileImage = user.profileImage;
  },

  RESET_USER(state) {
    // Add 'state' as a parameter
    state.id = "";
    state.firstName = "";
    state.secondName = "";
    state.thirdName = "";
    state.grade = "";
    state.userRole = "";
    state.profileImage = "/assets/images/default.jpg"; // Reset to default image path
  },
};

const actions = {
  setUser({ commit }, user) {
    commit("SET_USER", user);
  },
  resetUser({ commit }) {
    commit("RESET_USER");
  },
};

const getters = {
  user: (state) => state,
  userRole: (state) => state.userRole,
  userId: (state) => state.id,
  userGrade: (state) => state.grade,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
