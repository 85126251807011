<template>
  <footer class="footer">
    <p>&copy; {{ currentYear }} <span class="brand_1">Egy</span>Smart</p>
  </footer>
</template>

<script>
export default {
  name: "FooterComp",
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<style scoped>
.footer {
  background-color: #34495e;
  color: #ecf0f1;
  text-align: center;
  padding: 20px 0;
  font-size: 1.2em;
  /* positionF: fixed; */
  width: 100%;
  bottom: 0;
  left: 0;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 9999;
}
.brand_1 {
  color: #1abc9c !important;
}
</style>
