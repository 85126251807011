import { createI18n } from "vue-i18n";
import en from "./locales/en.json";
import ar from "./locales/ar.json";

const messages = {
  en: en,
  ar: ar,
};

const i18n = createI18n({
  locale: "ar", // default locale
  fallbackLocale: "ar", // fallback locale
  messages,
});

export default i18n;
