import { createStore } from "vuex";
// import axios from "axios";
import admin from "./modules/admin"; // Import the admin module
import user from "./modules/user";
import teacherProfile from "./modules/teacherProfile";
import persistedState from "./plugins/persistedState"; // Import the plugin

export default createStore({
  modules: {
    admin,
    user,
    teacherProfile,
  },
  plugins: [persistedState], // Add the plugin here
});
