// store/plugins/persistedState.js
export default (store) => {
  // Load state from sessionStorage on initialization
  const savedState = sessionStorage.getItem("vuex");
  if (savedState) {
    store.replaceState(Object.assign(store.state, JSON.parse(savedState)));
  }

  // Save state to sessionStorage whenever it changes
  store.subscribe((mutation, state) => {
    sessionStorage.setItem("vuex", JSON.stringify(state));
  });
};
