// const serverURL = "https://egysmart-5ae85a76bfd6.herokuapp.com:3000/";
// export default serverURL;

// src/config.js

// let serverURL;

// if (process.env.NODE_ENV === "production") {
//   serverURL =
//     // "http://www.egysmart.online/" ||
//     "https://egysmart-5ae85a76bfd6.herokuapp.com/";
// } else {
//   serverURL = "http://localhost:3000/"; // Local development URL
// }

// export default serverURL;

let serverURL;

if (process.env.NODE_ENV === "production") {
  serverURL = window.location.origin;
} else {
  serverURL = "http://localhost:3000/";
}

// Ensure the URL ends with a slash
if (!serverURL.endsWith("/")) {
  serverURL += "/";
}

export default serverURL;
